<template>
  <base-section id="services-list">
    <base-section-heading title="Services offerts">
      Voici une liste des services offerts par Impôt AD. Pour plus d'informations sur chacun de ces services, ou si vous avez une demande particulière relative à votre situation financière, n'hésitez pas à me contacter pour que nous puissions en discuter.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.description }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionServicesList',

    data: () => ({
      features: [
        {
          title: 'Déclaration de revenus de particuliers',
          icon: 'mdi-fountain-pen-tip',
          description: 'Préparation des déclarations de revenus fédérale et provinciale. Transmission électronique des documents.'
        },
        {
          title: 'Mise-à-jour fiscale',
          icon: 'mdi-table-arrow-up',
          description: 'Préparation des déclarations de revenus fédérale et provinciale des années antérieures.'
        },
        {
          title: 'Demande de redressement d\'une déclaration de revenus',
          icon: 'mdi-table-arrow-up',
          description: 'Modification d\'une déclaration de revenus déjà transmise.',
        },
        {
          title: 'Tenue de livres',
          icon: 'mdi-book-open-outline',
          description: 'Me contacter pour en savoir plus sur les services offerts.'
        },
        {
          title: 'Service personnalisé',
          icon: 'mdi-account-question',
          description: 'Pour toute demande relative à votre situation financière, il me fera plaisir de discuter avec vous pour explorer toutes les possibilités.'
        },
      ],
    }),
  }
</script>
